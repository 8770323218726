import React from 'react';
import './login.css';
import {setSession} from "./session";

class Login extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      usuario: '',
      senha: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.id]: event.target.value});
  }

  async handleSubmit(event) {
    event.preventDefault();
    await this.Logar();
  }
  

  Logar= async ()=>{  
    try {
      console.log(this.state);
      let response = await fetch("/api/autenticar", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers:{'Content-Type': 'application/json'},
        redirect: 'follow'
      });
      let ret = await response.json();
      this.SessaoRecuperar(ret.token);
    } catch (error) {
      console.log(error);
    }
  }
 
  SessaoRecuperar =  async (token) =>{
      /*
      var paramJson = { "chave": chave };
        $.ajax({
          type: "POST",
          url: "/api/sessaorecuperar",
          dataType: 'json',
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify(paramJson)
        }).done(function (ret) {
          if (ret.length > 0) {
            var obj = ret[0];
            setSession(obj);
            document.location.href = "/view/nflistar";
          }
          else {
            alert(ret.erro);
          }
        }).fail(function (ret) {
          console.log("erro:" + ret);
        });
        */

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", token);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch("/api/sessaorecuperar", requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result)
            result.token = token;
            setSession(result);
            window.location.href = "/nflistar";

          })
          .catch(error => console.log('error', error));
    }

    novoUsuario(){
      //document.location.href = "/view/formpessoa";
    }

    getElement(el){
      debugger;
      return el.value;
    }

  render(){

    return (
      <div>        
      <link rel="stylesheet" href="/pacotes/font-awesome-4.7.0/css/font-awesome.min.css"/>
      <link href="/pacotes/bootstrap-4.0.0/dist/css/bootstrap.min.css" rel="stylesheet" />
      <link href="/pacotes/bootstrap-4.0.0/dist/css/bootstrap-signin.css" rel="stylesheet" />   


      <div className="text-center">
        <img src="img/mercado-de-acoes-ou-forex-trading-grafico-no-conceito-grafico_73426-76.jpg" className="bg-fundo" alt="" />
          <form className="form-signin" id="frmLogar" onSubmit={this.handleSubmit} >
        
            <h1 style={{color:'#ffffff', 'textShadow': '5px 5px 10px #000000'}}>IFMAC</h1>
            <p>Sistema Gerenciador de Notas Fiscais Eletrônicas - NF-e</p>
            <br/>
            <label htmlFor="usuario" className="sr-only">Usuário</label>
            <input type="text" id="usuario" className="form-control" 
              value={this.state.usuario} 
              onChange={this.handleChange}
              placeholder="Usuário" 
              autoComplete="true" 
              required 
              autoFocus 
            />
            <label htmlFor="senha" className="sr-only">Senha</label>
            <input type="password" id="senha" className="form-control" 
              value={this.state.senha} 
              onChange={this.handleChange}
              placeholder="Senha" 
              autoComplete="true" 
              required
            />
            <div className="clearfix">
              <label className="pull-left checkbox-inline"><input type="checkbox" />
                <font style={{'verticalAlign': 'inherit'}}>
                  <font style={{'verticalAlign': 'inherit'}}>Lembre-se de mim </font>
                </font>
              </label>
              <button className="btn btn-primary btn-sm pull-right">
                <font style={{'verticalAlign': 'inherit'}}>
                  <font style={{'verticalAlign': 'inherit'}}>Esqueceu a senha?</font>
                </font>
              </button>
            </div>
            <br/>
            <button className="btn btn-primary btn-lg btn-block" type="submit" id="btnLogar">Logar</button>
            <button className="btn btn-outline-secondary btn-lg btn-block" type="button" id="btnNovoUsuario" onClick={this.novoUsuario}>Novo Usuário</button>
            <br/>
            <small>
              Ajuda: 
              <p>
                <a href="https://www.ifmac.com.br">https://www.ifmac.com.br</a><br/>
                <a href="mailto:cafelmac@gmail.com?Subject=Sistema Contabil NFe">cafelmac@gmail.com</a><br/>
                <a href="https://api.whatsapp.com/send?phone=5521986550871&amp;text=Olá! Gostaria de mais informações sobre">
                  <i className="fa fa-whatsapp text-success" aria-hidden="true"></i> (21) 98655-0871
                </a>
              </p>
            </small>
          </form>
      </div>
      </div>
    )
  }
}

export default Login;
