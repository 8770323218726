import React from 'react';
import { useRoutes } from "react-router-dom";

import Login from "./autenticar/Login.js";
import Nflistar from "./nf/nf-list.js";


function App() {
  let element = useRoutes([
    {path: "/", element: <Login />},
    {path: "/nflistar", element: <Nflistar />}
  ]);

  return element;
}

export default App;
