import { useState } from 'react';
import { Button, Modal} from 'antd';


function NfDetalhe({ objJson }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    if (objJson.procEventoNFe) {
        return (
            <>
            <Button type="primary" onClick={showModal}>
                Detalhe
            </Button>
            <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <NfEntrada objJson={objJson} />
            </Modal>
            </>
        )
    }
    else if (objJson.nfeProc) {
        //MontaNotaSaida(objJson);
        return (
            <>
                <Button type="primary" onClick={showModal}>
                Detalhe
            </Button>
            <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <NfSaida objJson={objJson} />
            </Modal>
            </>
        )
    }
}
function NfEntrada({ objJson }) {
    let NFe = objJson.procEventoNFe;
    let infEvento = NFe.evento.infEvento;

    return (
        <table className="table table-bordered" id="NfEntrada">
            <tr>
                <td>
                    <br />
                    <h6>infEvento</h6>
                    <table id="infEvento" className="table table-bordered table-sm small">
                        {
                            NFe.retEvento.infEvento.map((i, item) => {

                                <tr>
                                    <th>{i}</th>
                                    <td>
                                        <span>{item}</span>;
                                    </td>
                                </tr>
                            })
                        }

                    </table>
                    <h6>detEvento</h6>
                    <table id="detEvento" className="table table-bordered table-sm small">
                        <tr>
                            <th>CNPJ</th>
                            <td>{infEvento.CNPJ}</td>
                            <th>descEvento</th>
                            <td>{infEvento.dhEvento}</td>
                            <th>Id</th>
                            <td>{infEvento.Id}</td>
                        </tr>
                        <tr>
                            <th>descEvento</th>
                            <td>{infEvento.detEvento.descEvento}</td>
                            <th>versao</th>
                            <td>{infEvento.detEvento.versao}</td>
                            <th>xCorrecao</th>
                            <td>{infEvento.detEvento.xCorrecao}</td>
                        </tr>
                        <tr>
                            <th>xCondUso</th>
                            <td colSpan="5">{infEvento.detEvento.xCondUso}</td>
                        </tr>
                    </table>
                </td>
            </tr >
        </table >
    )
}
function NfSaida({ objJson }) {
    return (
        <table className="table table-bordered" id="NfSaida">
            <tbody>
            <tr>
                <td>
                    <br />
                    <table className="table table-bordered table-sm small">
                        <tr>
                            <td colSpan="2">
                                RECEBEMOS DE <span id="spnEmpresa"></span> OS PRODUTOS CONSTADOS DA NOTA FICAL
                            </td>
                            <td rowSpan="2">
                                <h5>NF-e</h5>
                                <h5>
                                    <span id="spnInfNF"></span>
                                </h5>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                DATA DO RECEBIMENTO
                                <br /><br />
                            </td>
                            <td>
                                CERTIFICAÇÃO E ASSINATURA DO RECEBIMENTO
                                <br /><br />
                            </td>
                        </tr>
                    </table>
                    <hr size="1" style={{"border":"1px dashed #aaaaaa;"}} />

                    <CarregaDadosNFe jsonNF={objJson}/>

                    <span>Destinatário</span>
                    <table id="dest" className="table table-bordered table-sm small"></table>
                    <span>infProt</span>
                    <table id="infProt" className="table table-bordered table-sm small"></table>
                    <span>ide</span>
                    <table id="ide" className="table table-bordered table-sm small"></table>
                    <span>DADOS DOS PRODUTOS / SERVIÇOS</span>
                    <table id="det" className="table table-bordered table-sm small"></table>
                    <span>CÁLCULO DO IMPOSTO</span>
                    <table id="total" className="table table-bordered table-sm small"></table>
                    <span>COBRAÇA</span>
                    <table id="cobr" className="table table-bordered table-sm small"></table>
                    <span>pag</span>
                    <table id="pag" className="table table-bordered table-sm small"></table>
                    <span>TRANSPORTADOR / VILUME TRANSPORTADO</span>
                    <table id="transp" className="table table-bordered table-sm small"></table>
                    <span>DADOS ADCIONAIS</span>
                    <table className="table table-bordered table-sm small">
                        <tr>
                            <td style={{ "width": "50%" }}>
                                <span>INFORMAÇÕES COMPLEMENTARES</span>
                                <br /><br />
                                <div id="infAdic" style={{"min-height": "100px"}}></div>
                            </td>
                            <td>
                                <span>RESERVADO AO FISCO</span>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
    )
}

function dadosNFe1 ({ protNFe, ide, emit }) {
    return (<>
        <tr>
            <td colSpan="3" style={{ "text-align": "right" }}><b>Versão:</b>{protNFe.versao}</td>
        </tr>
        <tr>
            <td>
                <b>{emit.xNome}</b>
                <br />
                <span>{emit.enderEmit.xLgr} {emit.enderEmit.nro}, {emit.enderEmit.xBairro}, {emit.enderEmit.xMun} - {emit.enderEmit.UF}
                    <br />CEP:{emit.enderEmit.CEP}, Tel.: {emit.enderEmit.fone}
                </span>
                <br />
            </td>
            <td>
                <h5>DARFE</h5>
                <span>Documento Auxiliar de Nota Fiscal Eletrônica</span><br />
                <b>Nº: </b>{ide.nNF}<br />
                <b>Série: </b>{ide.serie} <br />
                <b>Folha: </b>
            </td>
            <td><b>Controle do Fisco:</b><br />
                <div id="barcodeTarget" className="barcodeTarget"></div></td>
        </tr>

        <tr>
            <td colSpan="2">
                <b>Natureza da Operação:</b><br />
                {ide.natOp}
            </td>
            <td>
                <b>Protocolo de Autorização:</b>
                <br />{protNFe.infProt.nProt}
            </td>
        </tr>
        <tr>
            <td><b>Inscrição Estadual:</b><br />{emit.IE}</td>
            <td><b>CNPJ:</b><br />{emit.CNPJ}</td>
            <td><b>Chave de Acesso:</b><br />{protNFe.infProt.chNFe}
            </td>
        </tr>
    </>)

}

function dadosNFe2 ({ jsonNF, ide }) {
    return (
        <>
            <tr>
                <td>
                    <b>Modelo:</b><br />
                    {ide.mod}
                </td>
                <td><b>Número:</b><br />
                    {ide.nNF}
                </td>
                <td><b>Data de Emissão:</b><br />
                    {ide.dhEmi}
                </td>
                <td><b>Valor total da Nota Fiscal:</b><br />
                    {jsonNF.nfeProc.NFe.infNFe.pag.detPag.vPag}
                </td>
            </tr>
        </>
    )
}

function CarregaDadosNFe({ jsonNF }) {
    var protNFe = jsonNF.nfeProc.protNFe;
    var ide = jsonNF.nfeProc.NFe.infNFe.ide;
    var emit = jsonNF.nfeProc.NFe.infNFe.emit;
/*
    $('#spnEmpresa').text(emit.xNome);
    var spnInfNF = "<b>Nº: </b>" + ide.nNF + "<br>";
    spnInfNF += "<b>Série: </b>" + ide.serie + "<br>";
    $('#spnInfNF').html(spnInfNF);
*/
    

    return (
        <>
            <table id="dadosNFe1" className="table table-bordered table-sm small">
                <dadosNFe1
                    protNFe={protNFe}
                    ide={ide}
                    emit={emit}
                />
            </table>
            <table id="dadosNFe2" className="table table-bordered table-sm small">
                <dadosNFe2
                    protNFe={protNFe}
                    ide={ide}
                />
            </table>
        </>
    )
/*
    var settings = {
        output: "css",
        bgColor: "#FFFFFF",
        color: "#000000",
        barWidth: "1",
        barHeight: "30px",
        moduleSize: "5",
        posX: "10",
        posY: "20",
        addQuietZone: "1"
    };
    $("#barcodeTarget").html("").show().barcode(protNFe.infProt.chNFe, "codabar", settings);
    */
}
/*
function CarregaDest(jsonNF) {
    var dest = jsonNF.nfeProc.NFe.infNFe.dest;
    var html = "<tr>";
    html += "<td><b>CNPJ:</b><br>" + dest.CNPJ + "</td>";
    html += "<td><b>Nome / Razão Social:</b><br>" + dest.xNome + "</td>";
    html += "<td><b>Inscrição Estadual:</b><br>" + dest.IE + "</td>";
    html += "</tr>";
    html += "<tr>";
    html += "<td><b>Endereço:</b><br>" + dest.enderDest.xLgr + "</td>";
    html += "<td><b>Bairro:</b><br>" + dest.enderDest.xBairro + "</td>";
    html += "<td><b>CEP:</b><br>" + dest.enderDest.CEP + "</td>";
    html += "</tr>";
    html += "<tr>";
    html += "<td><b>Municipio:</b><br>" + dest.enderDest.xMun + "</td>";
    html += "<td><b>Telefone:</b><br>" + TrataVazio(dest.enderDest.fone) + "</td>";
    html += "<td><b>UF:</b><br>" + dest.enderDest.UF + "</td>";
    html += "</tr>";
    $('#dest').append(html);
}
function CarregaTransp(jsonNF) {
    var transp = jsonNF.nfeProc.NFe.infNFe.transp;
    var html = "";
    html += "<tr>";
    html += Td("Nome / Razão Social", transp.transporta ? TrataVazio(transp.transporta.xNome) : "");
    html += Td("IE", transp.transporta ? TrataVazio(transp.transporta.IE) : "");
    html += Td("CNPJ", transp.transporta ? TrataVazio(transp.transporta.CNPJ) : "");
    html += Td("modFrete", transp.transporta ? TrataVazio(transp.modFrete) : "");


    html += "</tr>";
    html += "<tr>";
    html += Td("Endereço", transp.transporta ? TrataVazio(transp.transporta.xEnder) : "");
    html += Td("Município", transp.transporta ? TrataVazio(transp.transporta.xMun) : "");
    html += Td("UF:", transp.transporta ? TrataVazio(transp.transporta.UF) : "");
    html += Td("esp", transp.transporta ? TrataVazio(transp.vol.esp) : "");
    html += "</tr>";
    html += "<tr>";
    html += Td("Qtd. Vol.", TrataVazio(transp.vol.qVol));
    html += Td("Marca", TrataVazio(transp.vol.marca));
    html += Td("Peso Bruto", TrataVazio(transp.vol.pesoB));
    html += Td("Peso Líquido", TrataVazio(transp.vol.pesoL));
    html += "</tr>";
    $('#transp').html(html);
}
function Td(label, valor, colspan) {
    var html = "";
    if (colspan) {
        html = "<td colSpan=\"" + colspan + "\">";
    }
    else {
        html = "<td>";
    }
    html += "<b>" + label + ":</b><br>";
    html += "<span>" + valor + "</span>";
    html += "</td>";

    return html;
}
function CarregaInfAdic(jsonNF) {
    var infAdic = jsonNF.nfeProc.NFe.infNFe.infAdic;
    var html = "";
    //var html =  "<b>"+getLabel("infCpl") + ":</b><br>";
    html += "<span>" + infAdic.infCpl + "</span>";
    $('#infAdic').html(html);
}
function MontaTabela(idTb, NFe, tColMax) {
    var tCol = 0;
    $.each(NFe, function (i, item) {
        var html = "";
        if (typeof (item) == "object") {
            return false;
        }
        if (tCol == 0 || tCol == tColMax) {
            $('#' + idTb).append("<tr></tr>");
            tCol = 0;
        }

        html += "<td>";
        html += "<b>" + getLabel(i) + ":</b><br>";
        html += "<span>" + item + "</span>";
        html += "</td>";

        var idx = $('#' + idTb + ' tr').length - 1;
        $('#' + idTb + ' tr').eq(idx).append(html);

        tCol++;
    });
}

function MontaNotaSaida(objJson) {
    CarregaDadosNFe(objJson);
    CarregaDest(objJson);
    CarregaTransp(objJson);
    CarregaInfAdic(objJson);
    var infNFe = objJson.nfeProc.NFe.infNFe;
    TbInfNFe(infNFe);
    //------------------
    var infProt = objJson.nfeProc.protNFe.infProt;
    MontaTabela('infProt', infProt, 3);
}
function TbInfNFe(NFe) {
    var tCol = 0;
    $.each(NFe, function (i, item) {
        var html = "";
        switch (i) {
            case "det": {
                //MontaTabela('det', item, 3);
                var html = "<tr>";
                html += "<th>CFOP</th>";
                html += "<th>NCM</th>";
                html += "<th>cEAN</th>";
                html += "<th>cEANTrib</th>";
                html += "<th>cProd</th>";
                html += "<th>indTot</th>";
                html += "<th>qCom</th>";
                html += "<th>qTrib</th>";
                html += "<th>uCom</th>";
                html += "<th>uTrib</th>";
                html += "<th>vProd</th>";
                html += "<th>vUnCom</th>";
                html += "<th>vUnTrib</th>";
                html += "<th>xProd</th>";
                html += "</tr>";
                $('#det').append(html);
                if (item.length) {
                    $.each(item, function (j, obj) {
                        html = "<tr>";
                        html += "<td>" + obj.prod.CFOP + "</td>";
                        html += "<td>" + obj.prod.NCM + "</td>";
                        html += "<td>" + JSON.stringify(obj.prod.cEAN).replace("{}", "") + "</td>";
                        html += "<td>" + JSON.stringify(obj.prod.cEANTrib).replace("{}", "") + "</td>";
                        html += "<td>" + obj.prod.cProd + "</td>";
                        html += "<td>" + obj.prod.indTot + "</td>";
                        html += "<td>" + obj.prod.qCom + "</td>";
                        html += "<td>" + obj.prod.qTrib + "</td>";
                        html += "<td>" + obj.prod.uCom + "</td>";
                        html += "<td>" + obj.prod.uTrib + "</td>";
                        html += "<td>" + obj.prod.vProd + "</td>";
                        html += "<td>" + obj.prod.vUnCom + "</td>";
                        html += "<td>" + obj.prod.vUnTrib + "</td>";
                        html += "<td>" + obj.prod.xProd + "</td>";
                        html += "</tr>";
                        $('#det').append(html);
                    });
                }
                else {
                    if (item.prod) {
                        html = "<tr>";
                        html += "<td>" + TrataVazio(item.prod.CFOP) + "</td>";
                        html += "<td>" + TrataVazio(item.prod.NCM) + "</td>";
                        html += "<td>" + TrataVazio(item.prod.cEAN).replace("{ }", "") + "</td>";
                        html += "<td>" + TrataVazio(item.prod.cEANTrib).replace("{ }", "") + "</td>";
                        html += "<td>" + TrataVazio(item.prod.cProd) + "</td>";
                        html += "<td>" + TrataVazio(item.prod.indTot) + "</td>";
                        html += "<td>" + TrataVazio(item.prod.qCom) + "</td>";
                        html += "<td>" + TrataVazio(item.prod.qTrib) + "</td>";
                        html += "<td>" + TrataVazio(item.prod.uCom) + "</td>";
                        html += "<td>" + TrataVazio(item.prod.uTrib) + "</td>";
                        html += "<td>" + TrataVazio(item.prod.vProd) + "</td>";
                        html += "<td>" + TrataVazio(item.prod.vUnCom) + "</td>";
                        html += "<td>" + TrataVazio(item.prod.vUnTrib) + "</td>";
                        html += "<td>" + TrataVazio(item.prod.xProd) + "</td>";
                        html += "</tr>";
                        $('#det').append(html);
                    }
                }
                break;
            }
            case "total": {
                MontaTabela('total', item.ICMSTot, 6);
                break;
            }
            case "cobr": {
                MontaTabela('cobr', item.fat, 5);
                break;
            }
            case "pag": {
                MontaTabela('pag', item.detPag, 5);
                break;
            }
            case "ide": {
                MontaTabela('ide', item, 6);
                break;
            }
        }
    });
}


function getLabel(valor) {
    var label = valor;
    var lstLabel = {
        "infCpl": "Descrição"
    }
    if (lstLabel[valor]) {
        label = lstLabel[valor]
    }
    return label;
}
*/
export default NfDetalhe;