import React from 'react';
//import MenuTop from "../layout/menu-top";
//import MenuLeft from "../layout/menu-left";
//import Footer from '../layout/footer';


//import DataTable from 'react-data-table-component';

import { Layout, Button, Table, Card, Badge } from 'antd';

import { getSession } from "../autenticar/session";
import UploadXML from "./nf-upload-xml";
import NfDetalhe from './nf-detalhar';


const { Header, Footer, Sider, Content } = Layout;

class Nflistar extends React.Component {
    constructor(props) {
        super(props);
        this.session = getSession();
        this.state = {
            pending: true,
            data: []
        }
        this.NFExcluir = this.NFExcluir.bind(this);
        this.clearState = this.clearState.bind(this);
    }

    componentDidMount() {
        //document.title = `You clicked ${this.state.count} times`;
        this.NFListar();

    }
    componentDidUpdate() {
        //document.title = `You clicked ${this.state.count} times`;
        //if (this.state.data.length === 0){
        this.NFListar();
        //}
    }


    NFListar() {

        if (!this.state.pending) {
            return;
        }

        fetch('/api/nf/listar', {
            method: 'POST',
            headers: {
                'x-access-token': sessionStorage.getItem("token"),
                'content-type': 'application/json'
            },
            mode: 'cors',
            cache: 'no-cache',
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        })
            .then(res => res.json())
            .then((result) => {
                if (result.auth && !result.auth){
                    document.location.href="/";
                }
                this.setState({
                    pending: false,
                    data: result?.map(item => {
                        return {
                            key: item._id,
                            id_pessoa: item.id_pessoa,
                            dt_cadastro: item.dt_cadastro,
                            nf_json: item.nf_json,
                        }
                    })
                })
            })
    }
    NFExcluir(event) {

        event.preventDefault();
        let id = event.currentTarget.getAttribute('_id');
        let token = this.session.token
        if (!id) {
            return;
        }

        let param = JSON.stringify({
            id: id
        })

        fetch('/api/nf/excluir_xml', {
            method: 'POST',
            headers: {
                'x-access-token': token,
                'content-type': 'application/json'
            },
            body: param,
            mode: 'cors',
            cache: 'no-cache',
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        })
            .then(res => res.json())
            .then((result) => {
                this.clearState();
            }).catch((ret) => {
                console.log(ret);
            })
    }

    clearState() {
        this.setState({
            pending: true,
            data: []
        });
    }


    render() {


        const columns = [
            {
                title: 'id_pessoa',
                dataIndex: 'id_pessoa',
                key: 'id_pessoa'
            },
            {
                title: 'dt_cadastro',
                dataIndex: 'dt_cadastro',
                key: 'dt_cadastro',
            },
            {
                title: 'nf_json',
                dataIndex: 'nf_json',
                render: (_, record) => {
                    return <NfDetalhe objJson={record.nf_json}></NfDetalhe>
                },
            }
            ,
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => {
                    return <Button _id={record.key} onClick={this.NFExcluir}>Excluir</Button>
                },
            }
        ];

        if (this.state.pending) {
            return <h1>Aguarde...</h1>
        }
        else {
            return (
                <>
                    <Content>
                        <Card title="Nota Fiscal Eletrônica (NF-e)" bordered={false}>
                            <UploadXML clearState={this.clearState} />
                            <Badge count={this.state.data.length} color='#faad14' />
                            <Table columns={columns} dataSource={this.state.data} />

                            {
                                /*
                            <Tag>You clicked {this.state.count} times</Tag>
                            
                                
                                <button onClick={() => this.setState({ count: this.state.count + 1 })}>
                                Click me
                            </button>
                                */
                            }
                        </Card>
                    </Content>
                </>
            );
        }

    }
}

export default Nflistar;
/*
<!-- start page title -->
<div class="row">
    <div class="col-12">
        <div class="page-title-box">
            <div class="page-title-right">
                <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="javascript: void(0);">UBold</a></li>
                    <li class="breadcrumb-item"><a href="javascript: void(0);">Tables</a></li>
                    <li class="breadcrumb-item active">Datatables</li>
                </ol>
            </div>
            <h4 class="page-title">Datatables</h4>
        </div>
    </div>
</div>
<!-- end page title -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body" style="padding: 2px;">
                <div class="row">
                    <div class="col-6">
                        <h6>Pasta</h6>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn" data-toggle="modal" data-target="#modalNovaPasta" title="Criar pasta">
                            <i class="fa fa-folder" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div id="lstPasta">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body" style="padding-bottom: 2px;padding-left: 14px;padding-right: 14px;">
                <div class="row">
                    <div class="col-6">
                        <h6>Notas Fiscais</h6>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn" data-toggle="modal" data-target="#modalUpload" title="Upload de arquivos">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm table-responsive" style="padding: 0px;">

                    <table id="gridList" 
                        data-url=""
                        class="table table-hover display small"
                        data-filter-control="true"
                        data-show-search-clear-button="true"
                        data-show-export="true"
                        data-pagination="true"
                        data-side-pagination="server"
                        data-click-to-select="true"
                        data-toolbar="#toolbar"
                        data-show-toggle="true"
                        data-show-columns="true"
                        >
                        <thead>
                            <tr>
                                <th data-field="name" data-filter-control="input">Chave de acesso</th>
                                <th data-field="name2" data-filter-control="input" style="min-width: 50px">Nº NF-e</th>
                                <th data-field="name3" data-filter-control="input" style="min-width: 50px">Tipo</th>
                                <th data-field="name4" data-filter-control="input" nowrap>Data de Emissão</th>
                                <th data-field="name5" data-filter-control="input">Nome</th>
                                <th data-field="name6" data-filter-control="input">Operação</th>
                                <th data-field="name7" data-filter-control="input">CFOP</th>
                                <th data-field="name8" data-filter-control="input">ICMS</th>
                                <th data-field="name9" data-filter-control="input">IPI</th>
                                <th data-field="name10" data-filter-control="input">CST</th>
                                <th data-field="name11" data-filter-control="input">CNPJ emit</th>
                                <th data-field="name12" data-filter-control="input">UF</th>
                                <th data-field="name13" data-filter-control="input">Status</th>
                                <th data-field="name14" data-filter-control="input">Ins. Est. Fornecedor</th>
                                <th data-field="name15" data-filter-control="input">vTotTrib</th>
                                <th data-field="name16" data-filter-control="input">Vl. Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div> <!-- end card body-->
        </div> <!-- end card -->
    </div><!-- end col-->
</div>
<!-- end row-->
<div class="row">
    <div class="col-lg-12">
        <div class="card-box" dir="ltr">
            <h4 class="header-title">Totais</h4>
            <!--p class="sub-header">
    Example of Horizontal bar chart.
</p-->

            <div id="simple-line-chart" class="ct-chart ct-golden-section"></div>
        </div> <!-- end card-box -->
    </div> <!-- end col -->
</div>
<!-- Modal -->
<div class="modal fade" id="modalUpload" tabindex="-1" role="dialog" aria-labelledby="modalNovaPastaLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalUploadLabel">Cadastar NF</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <ul class="nav nav-tabs nav-bordered">
                    <li class="nav-item">
                        <a href="#profile-b1" data-toggle="tab" aria-expanded="false" class="nav-link active">
                            Arquivo XML 4.0
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#home-b1" data-toggle="tab" aria-expanded="true" class="nav-link">
                            Chave NF
                        </a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane show active" id="profile-b1">
                        <div class="form-group">
                            <input type="file" id="filetoupload" name="filetoupload" class="form-control-file"
                                accept='text/xml' multiple />
                            <!--Adcionar [multiple] para multipos arquivos-->
                        </div>
                        <div id="fileDet"></div>
                    </div>
                    <div class="tab-pane" id="home-b1">
                        <input type="text" id="chaveNf" name="chaveNf" class="form-control" />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" id="btnSalvarFile" class="btn btn-primary">Salvar</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="modalNovaPasta" tabindex="-1" role="dialog" aria-labelledby="modalUploadLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        
    </div>
</div>

<link href="https://unpkg.com/bootstrap-table@1.19.1/dist/bootstrap-table.min.css" rel="stylesheet">

<script src="https://cdn.jsdelivr.net/npm/tableexport.jquery.plugin@1.10.21/tableExport.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/tableexport.jquery.plugin@1.10.21/libs/jsPDF/jspdf.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/tableexport.jquery.plugin@1.10.21/libs/jsPDF-AutoTable/jspdf.plugin.autotable.js"></script>
<script src="https://unpkg.com/bootstrap-table@1.19.1/dist/bootstrap-table.min.js"></script>
<script src="https://unpkg.com/bootstrap-table@1.19.1/dist/extensions/export/bootstrap-table-export.min.js"></script>

<style>
#toolbar {
  margin: 0;
}
</style>

<!--
<table id="table"
  data-show-export="true"
  data-pagination="true"
  data-side-pagination="server"
  data-click-to-select="true"
  data-toolbar="#toolbar"
  data-show-toggle="true"
  data-show-columns="true"
  data-url="https://examples.wenzhixin.net.cn/examples/bootstrap_table/data">
</table>

<script>
  var $table = $('#table')

  $(function() {
    $('#toolbar').find('select').change(function () {
      $table.bootstrapTable('destroy').bootstrapTable({
        exportDataType: $(this).val(),
        exportTypes: ['json', 'xml', 'csv', 'txt', 'sql', 'excel', 'pdf'],
        columns: [
          {
            field: 'state',
            checkbox: true,
            visible: $(this).val() === 'selected'
          },
          {
            field: 'id',
            title: 'ID'
          }, {
            field: 'name',
            title: 'Item Name'
          }, {
            field: 'price',
            title: 'Item Price'
          }
        ]
      })
    }).trigger('change')
  })
</script>
-->
<script type="text/javascript">
    $(function () {

        NFPastaListar()
        //NFlistar();        
        //RecarregaGrid();

        //let data = NFlistar2();


        function RecarregaGrid() {

            //setTimeout(()=>{
            $('#gridList').DataTable().destroy();
            //}, 1000);


            var colVisivel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
            var table = $('#gridList').DataTable({
                orderCellsTop: true,
                lengthMenu: [[5, -1], [5, "All"]],
                dom: 'Bfrtip',
                language: ptbr,
                responsive: false,
                scrollX: true,
                buttons: [
                    {
                        extend: 'copyHtml5',
                        text: '<i class="fa fa-clone" aria-hidden="true" title="Copiar"></i>',
                        titleAttr: 'Copiar',
                        exportOptions: {
                            columns: colVisivel
                        }
                    },
                    {
                        extend: 'csvHtml5',
                        text: '<i class="fa fa-file-text-o" aria-hidden="true" title="CSV"></i>',
                        titleAttr: 'CSV',
                        exportOptions: {
                            columns: colVisivel
                        }
                    },
                    {
                        extend: 'excelHtml5',
                        text: '<i class="fa fa-file-excel-o" aria-hidden="true" title="Excel"></i>',
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: colVisivel
                        }
                    },
                    {
                        extend: 'pdfHtml5',
                        text: '<i class="fa fa-file-pdf-o" aria-hidden="true" title="PDF"></i>',
                        titleAttr: 'PDF',
                        orientation: 'landscape',
                        pageSize: 'LEGAL',
                        exportOptions: {
                            columns: colVisivel
                        }
                    },
                    {
                        extend: 'print',
                        text: '<i class="fa fa-print" aria-hidden="true" title="Imprimir"></i>',
                        titleAttr: 'Imprimir',
                        exportOptions: {
                            columns: colVisivel
                        }
                    }
                ]
            
            });

            //new $.fn.dataTable.FixedHeader(table);
        }

        $('#gridList tbody tr').click(function () {
            $('#gridList tbody tr').removeClass('table-warning');
        });

        $("#btnSalvarFile").click(function () {
            var obj = document.getElementById('filetoupload');
            $.each(obj.files, function (i, item) {
                getTextArquivo(item, function (xml) {
                    SalvarXML(xml, function (ret) {
                        if (ret.erro == 0) {
                            $("#fileDet").append("<li>" + item.name + " - ok</li>");
                        }
                        else {
                            $("#fileDet").append("<li style=\"color:#ff0000\">" + item.name + " - erro</li>");
                        }
                    });
                });
            });
            document.location.href = document.location.href;
        });

        $('#btnSalvarPasta').click(function () {
            PastaSalvar();
        });
    });
/*

    function NFlistar2(id_pasta) {
        let paramJson = {
            "id_pessoa": sessionStorage.getItem("id_pessoa"),
            "chave": sessionStorage.getItem("ds_chave"),
            "id_pasta": id_pasta
        };

        let grafVal = {
            labels: [],
            series: []
        };

        let totNf = 0;
        $.ajax({
            type: "POST",
            url: "../api/nf/listar",
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(paramJson),
            async: false
        }).done(function (ret) {
            var contOk = [];
            var conterro = [];
            $('#gridList tbody').find('tr').remove();

            let newJson = []

            $.each(ret, function (i, item) {
                var str_json_nf = JSON.parse(item.str_json_nf);
                if (!str_json_nf) {
                    return;
                }
                let lin = {
                    id: i,
                    chave_acesso: "",
                    nu_NFe: "",
                    tipo: "",
                    dt_emissao: "",
                    nome: "",
                    operacao: "",
                    CFOP: null,
                    ICMS: null,
                    IPI: null,
                    CST: null,
                    CNPJ_emit: null,
                    UF: null,
                    status: null,
                    ins_est_fornecedor: null,
                    vTotTrib: null,
                    vTotal: null,
                    acao: null
                }

                if (str_json_nf.procEventoNFe) { //Entrada
                    lin.chave_acesso = str_json_nf.procEventoNFe.retEvento.infEvento.chNFe;
                    lin.nu_NFe = str_json_nf.procEventoNFe.retEvento.infEvento.nProt;
                    lin.tipo = "0-Entrada";
                    lin.dt_emissao = str_json_nf.procEventoNFe.retEvento.infEvento.dhRegEvento;
                    lin.nome = str_json_nf.procEventoNFe.retEvento.infEvento.xEvento;
                    lin.UF = str_json_nf.procEventoNFe.retEvento.infEvento.CNPJDest;
                    lin.status = str_json_nf.procEventoNFe.retEvento.infEvento.cStat;
                    lin.acao = `
                        <span class=\"badge badge-pill badge-primary\" onclick=\"ConsultarSefaz('${str_json_nf.procEventoNFe.retEvento.infEvento.chNFe}')\" style=\"cursor:pointer\">Consultar Sefaz</span>
                        <span class=\"badge badge-pill badge-info\" onclick=\"NotaDetalhar(${item.id_nota_fiscal})\" style=\"cursor:pointer\">Detalhe</span>
                        <span class=\"badge badge-pill badge-danger\" onclick=\"Excluir(${item.id_nota_fiscal})\" style=\"cursor:pointer\">Excluir</span>
                    `
                }
                else if (str_json_nf.nfeProc) { //Saída
                    var infNFe = str_json_nf.nfeProc.NFe.infNFe;

                    lin.chave_acesso = infNFe.Id.replace("NFe", "");
                    lin.nu_NFe = infNFe.ide.nNF;
                    lin.tipo = ((infNFe.ide.tpNF == 1) ? "1-Saida" : "0-Entrada");
                    lin.dt_emissao = infNFe.ide.dhEmi;
                    lin.nome = infNFe.emit.xNome;
                    lin.operacao = infNFe.ide.natOp;
                    lin.CFOP = ConcatenaCFOP(infNFe.det);
                    lin.ICMS = infNFe.total.ICMSTot.vICMS;
                    lin.IPI = infNFe.total.ICMSTot.vIPI;
                    lin.CST = infNFe.total.ICMSTot.vBCST;
                    lin.CNPJ_emit = infNFe.emit.CNPJ;
                    lin.UF = infNFe.emit.enderEmit.UF;
                    lin.status = str_json_nf.nfeProc.protNFe.infProt.cStat;
                    lin.ins_est_fornecedor = infNFe.emit.IE;
                    lin.vTotTrib = infNFe.total.ICMSTot.vTotTrib;
                    lin.vTotal = infNFe.total.ICMSTot.vNF;
                    lin.acao = `
                        <span class=\"badge badge-pill badge-primary\" onclick=\"ConsultarSefaz('${str_json_nf.nfeProc.NFe.infNFe.Id.replace("NFe", "")}')\" style=\"cursor:pointer\">Consultar Sefaz</span>
                        <span class=\"badge badge-pill badge-info\" onclick=\"NotaDetalhar(${item.id_nota_fiscal})\" style=\"cursor:pointer\">Detalhe</span>
                        <span class=\"badge badge-pill badge-danger\" onclick=\"Excluir(${item.id_nota_fiscal})\" style=\"cursor:pointer\">Excluir</span>
                    `
                }

                newJson.push(lin);
            });

            //RecarregaGrid2(newJson);
            

        }).fail(function (ret) {
            console.log(ret);
        });
    }



    
    function NFlistar(id_pasta) {
        let paramJson = {
            "id_pessoa": sessionStorage.getItem("id_pessoa"),
            "chave": sessionStorage.getItem("ds_chave"),
            "id_pasta": id_pasta
        };

        let grafVal = {
            labels: [],
            series: []
        };

        let totNf = 0;
        $.ajax({
            type: "POST",
            url: "../api/nf/listar",
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(paramJson),
            async: false
        }).done(function (ret) {
            var contOk = [];
            var conterro = [];
            $('#gridList tbody').find('tr').remove();
            $.each(ret, function (i, item) {
                var str_json_nf = JSON.parse(item.str_json_nf);
                if (str_json_nf.procEventoNFe) { //Entrada
                    var html = "<tr>";
                    html += "<td>" + str_json_nf.procEventoNFe.retEvento.infEvento.chNFe + "</td>";
                    html += "<td>" + str_json_nf.procEventoNFe.retEvento.infEvento.nProt + "</td>";
                    html += "<td>0-Entrada</td>";
                    html += "<td>" + str_json_nf.procEventoNFe.retEvento.infEvento.dhRegEvento + "</td>";
                    html += "<td>" + str_json_nf.procEventoNFe.retEvento.infEvento.xEvento + "</td>";
                    html += "<td></td>";
                    html += "<td></td>";
                    html += "<td></td>";
                    html += "<td></td>";
                    html += "<td></td>";
                    html += "<td></td>";
                    html += "<td>" + str_json_nf.procEventoNFe.retEvento.infEvento.CNPJDest + "</td>";
                    html += "<td>" + str_json_nf.procEventoNFe.retEvento.infEvento.cStat + "</td>";
                    html += "<td></td>";
                    html += "<td></td>";
                    html += "<td></td>";
                    html += "<td>";
                    html += "<h5>";
                    html += "<span class=\"badge badge-pill badge-primary\" onclick=\"ConsultarSefaz('" + str_json_nf.procEventoNFe.retEvento.infEvento.chNFe + "')\" style=\"cursor:pointer\">Consultar Sefaz</span>";
                    html += "<span class=\"badge badge-pill badge-info\" onclick=\"NotaDetalhar(" + item.id_nota_fiscal + ")\" style=\"cursor:pointer\">Detalhe</span>";
                    html += "<span class=\"badge badge-pill badge-danger\" onclick=\"Excluir(" + item.id_nota_fiscal + ")\" style=\"cursor:pointer\">Excluir</span>";
                    html += "</h5>";
                    html += "</td>";
                    html += "</tr>";
                    $('#gridList tbody').append(html);
                }
                if (str_json_nf.nfeProc) { //Saída
                    var infNFe = str_json_nf.nfeProc.NFe.infNFe;
                    var html = "<tr>";
                    html += "<td>" + infNFe.Id.replace("NFe", "") + "</td>";
                    html += "<td>" + infNFe.ide.nNF + "</td>";
                    html += "<td>" + ((infNFe.ide.tpNF == 1) ? "1-Saida" : "0-Entrada") + "</td>";
                    html += "<td>" + infNFe.ide.dhEmi + "</td>";
                    html += "<td>" + infNFe.emit.xNome + "</td>";
                    html += "<td>" + infNFe.ide.natOp + "</td>";
                    html += "<td>" + ConcatenaCFOP(infNFe.det) + "</td>";
                    html += "<td>" + infNFe.total.ICMSTot.vICMS + "</td>";
                    html += "<td>" + infNFe.total.ICMSTot.vIPI + "</td>";
                    html += "<td>" + infNFe.total.ICMSTot.vBCST + "</td>";
                    html += "<td>" + infNFe.emit.CNPJ + "</td>";
                    html += "<td>" + infNFe.emit.enderEmit.UF + "</td>";
                    html += "<td>" + str_json_nf.nfeProc.protNFe.infProt.cStat + "</td>";
                    html += "<td>" + infNFe.emit.IE + "</td>";
                    html += "<td>" + infNFe.total.ICMSTot.vTotTrib + "</td>";
                    html += "<td>" + infNFe.total.ICMSTot.vNF + "</td>";
                    html += "<td>";
                    html += "<h5>";
                    html += "<span class=\"badge badge-pill badge-primary\" onclick=\"ConsultarSefaz('" + str_json_nf.nfeProc.NFe.infNFe.Id.replace("NFe", "") + "')\" style=\"cursor:pointer\">Consultar Sefaz</span>";
                    html += "<span class=\"badge badge-pill badge-info\" onclick=\"NotaDetalhar(" + item.id_nota_fiscal + ")\" style=\"cursor:pointer\">Detalhe</span>";
                    html += "<span class=\"badge badge-pill badge-danger\" onclick=\"Excluir(" + item.id_nota_fiscal + ")\" style=\"cursor:pointer\">Excluir</span>";
                    html += "</h5>";
                    html += "</td>";
                    html += "</tr>";
                    $('#gridList tbody').append(html);
                }
            });
            
            
            $('#gridList').bootstrapTable('destroy')            
                .bootstrapTable({
                   exportDataType: '',
                    exportTypes: ['json', 'xml', 'csv', 'txt', 'sql', 'excel', 'pdf'],
                });
                    
                $('[name="toggle"]').removeClass().addClass('btn btn-white waves-effect');
            $('.fa.fa-toggle-on').removeClass().addClass('fas fa-toggle-on');
            $('.fa.fa-toggle-off').removeClass().addClass('fas fa-toggle-off');

            $('[name="toggle"]').click(()=>{
                $('.fa.fa-toggle-on').removeClass().addClass('fas fa-toggle-on');
                $('.fa.fa-toggle-off').removeClass().addClass('fas fa-toggle-off');
            });
           
            $('[aria-label="Columns"').removeClass().addClass('btn btn-white waves-effect').html('<i class="fa fa-th-list" aria-hidden="true"></i>');
            $('[aria-label="Export"]').removeClass().addClass('btn btn-white waves-effect').html('<i class="fas fa-file-export" aria-hidden="true"></i>');

            
       
            let json = [];
            document.querySelectorAll('#gridList tbody tr').forEach(function (item, i) {
                json.push({
                    dtEmissao: item.querySelectorAll('td')[3].innerText,
                    vlTotal: item.querySelectorAll('td')[15].innerText
                });

                //                   console.log(vlTotal);
            });

            json.sort(GetSortOrder('dtEmissao'));

            json.forEach(item => {
                let dt = item.dtEmissao.substr(0, 10); //Mês
                //let dt = item.dtEmissao.substr(0, 7); //Dia
                if (grafVal.labels.indexOf(dt) == -1) {
                    grafVal.labels.push(dt);
                }
            });


            let lstSerie = [];
            grafVal.labels.forEach((label) => {

                json.filter((item) => {
                    if (item.dtEmissao.indexOf(label) != -1) {
                        totNf += parseFloat(item.vlTotal);
                    }
                });

                lstSerie.push(totNf);
                totNf = 0;
            })
            grafVal.series.push(lstSerie);

            MontaGraficoSida(grafVal);
            
        }).fail(function (ret) {
            console.log(ret);
        });
    }

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    function ConcatenaCFOP(obj) {
        var ret = "";
        $.each(obj, function (i, item) {
            if (item.prod) {
                if (ret != "") {
                    if (ret.indexOf(item.prod.CFOP) == -1) {
                        ret += ", " + item.prod.CFOP;
                    }
                }
                else {
                    ret = item.prod.CFOP;
                }
            }
        });
        return ret
    }
    function ConsultarSefaz(nf) {
        var url = "http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&tipoConteudo=XbSeqxE8pl8=&nfe=" + nf;
        var janela = window.open(url);
    }
    function NotaDetalhar(ID) {
        var detalhe = window.open("nfdetalhar?id=" + ID);
    }

    function getTextArquivo(file, fx_callback) {
        var fReader = new FileReader();
        //fReader.readAsDataURL(file);       //Conficuração para retornar no load a string base64
        fReader.readAsText(file);            //Configuração para retornar a string do arquivo
        fReader.onloadend = function (event) {
            //alert(event.target.result);
            //img.src = event.target.result;
            fx_callback(event.target.result);

        }
    }
    function SalvarXML(xml, fx_callback) {

        let paramJson = {
            "id_pessoa": sessionStorage.getItem("id_pessoa"),
            "chave": sessionStorage.getItem("ds_chave"),
            "xml": xml
        }
        $.ajax({
            type: "POST",
            url: "../api/nf/upload_xml",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(paramJson),
            async: false
        }).done(function (ret) {
            fx_callback(ret);
        });
    }

    function PastaSalvar() {
        let paramJson = {
            "id_pessoa": sessionStorage.getItem("id_pessoa"),
            "chave": sessionStorage.getItem("ds_chave"),
            "nm_pasta": $('#nm_pasta').val()
        }
        $.ajax({
            type: "POST",
            url: "../api/nf/pastasalvar",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(paramJson),
            async: false
        }).done(function (ret) {
            //fx_callback(ret);
            NFPastaListar();
        });
    }
    function NFPastaListar() {
        let paramJson = {
            "id_pessoa": sessionStorage.getItem("id_pessoa"),
            "chave": sessionStorage.getItem("ds_chave"),
        }

        $.ajax({
            type: "POST",
            url: "../api/nf/pastalistar",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(paramJson),
            async: false
        }).done(function (ret) {

            //$('#lstPasta').find('option').remove();
            let html = ``;
            ret.forEach(item => {
                
               let html = `
                <div class="col-xl-3 col-lg-6">
                    <div class="card m-1 shadow-none border">
                        <div class="p-2">
                            <div class="row align-items-center">
                                <div class="col-auto">
                                    <div class="avatar-sm">
                                        <span class="avatar-title bg-light text-secondary rounded">
                                            <i class="mdi mdi-folder font-18"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="col pl-0">
                                    <a href="${item.id_pasta}" class="text-muted font-weight-medium">${item.nm_pasta}n</a>
                                    <p class="mb-0 font-13">87.2 MB</p>
                                </div>
                            </div> <!-- end row -->
                        </div> <!-- end .p-2-->
                    </div> <!-- end col -->
                </div>
                `

                html += `
                        <a href="javascript:NFlistar(${item.id_pasta})" class="btn btn-white waves-effect">${item.nm_pasta}</a>
                        `
            });
            $('#lstPasta').html(html);

        });
    }
    function Excluir(id) {
        let paramJson = {
            "id": id.toString(),
            "chave": sessionStorage.getItem("ds_chave"),
        }
        $.ajax({
            type: "POST",
            url: "../api/nf/excluir_xml",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(paramJson),
            async: false
        }).done(function (ret) {
            if (ret.erro == 0) {
                alert(ret.msg);
                document.location.href = document.location.href;
            }
        });
    }


    function MontaGraficoSida(json) {
       
        new Chartist.Line("#simple-line-chart",
            {
                labels:["Segunda","Terça","Quarta","Quinta","Sexta"],
                series:[
                  [12,9,7,8,5],
                  [2,1,3.5,7,3],
                  [1,3,4,5,6]
                 ]
            },
            {
                fullWidth:!0,
                chartPadding:{right:40},
                plugins:[Chartist.plugins.tooltip()]
            }
        );
        

        new Chartist.Line("#simple-line-chart",
            {
                labels: json.labels,
                series: json.series
            },
            {
                fullWidth: !0,
                chartPadding: { right: 40 },
                plugins: [Chartist.plugins.tooltip()]
            }
        );
    }

    
</script>
*/