
function setSession(obj){
    sessionStorage.setItem("token", obj.token);
    sessionStorage.setItem("id_pessoa", obj.id_pessoa);
    sessionStorage.setItem("id_cliente", obj.id_cliente);
    sessionStorage.setItem("nm_pessoa", obj.nm_pessoa);
    sessionStorage.setItem("ds_email", obj.ds_email);
    sessionStorage.setItem("ds_usuario", obj.ds_usuario);
}

function getSession(){
    if(sessionStorage.getItem("id_pessoa") 
    && sessionStorage.getItem("token")){
        return {
            token: sessionStorage.getItem("token"),
            id_pessoa: sessionStorage.getItem("id_pessoa"),
            id_cliente: sessionStorage.getItem("id_cliente"),
            nm_pessoa: sessionStorage.getItem("nm_pessoa"),
            ds_email: sessionStorage.getItem("ds_email"),
            ds_usuario: sessionStorage.getItem("ds_usuario"),
        }
    }else{
        document.location.href = "/";
    }
};

export {setSession, getSession};
