
import { useState } from 'react';

import { Button, Modal, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { getSession } from "../autenticar/session";

const UploadXML = ({ clearState }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const session = getSession();

    const config = {
        action: '/api/nf/upload_xml',
        name: "xml",
        data: { "id_pessoa": session.id_pessoa },
        headers: {
            "x-access-token": session.token,
        },
        onChange({ file, fileList }) {
            //this.setState({pending: true});
            if (file.status !== 'uploading') {
                console.log(file, fileList);
            }
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        clearState();
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Button type="primary" onClick={showModal}>
                Adicionar
            </Button>
            <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Upload {...config} accept='text/xml' multiple>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
            </Modal>
        </>
    );
};
export default UploadXML;